import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @Input() msg; 
  @Input() route; 

  constructor(
    public activeModal: NgbActiveModal, 
    public _Router: Router 
  ) { }

  ngOnInit() {
    this._Router.navigate([this.route]);  
  }

}
