import { Injectable } from '@angular/core';

import { AppGlobals } from '@app/core/app.globals';
import { HttpClient } from '@angular/common/http';
//import { ResponseContentType } from '@angular/http';

@Injectable()
export class PdfService {
  api_url = '';
  

  constructor(private _global: AppGlobals,private http: HttpClient) { }

  saveFile(date) {
    this.api_url = this._global.baseAPIUrl+'createpdf/createpdf';
    let urlPdf=this.api_url+'?date='+date+'&token='+this.getToken();
    console.log("urlPDF="+urlPdf);
    window.open(this.api_url+'?date='+date+'&token='+this.getToken(),'_blank');
  }
 /* downloadPDF(url): any {
    const options = { responseType: ResponseContentType.Blob  };
    return this.http.get(url, options).map(
    (res) => {
        return new Blob([res.blob()], { type: 'application/pdf' });
    });
  }*/
  getToken(): string {
    return JSON.parse(localStorage.getItem('currentUser')).token;
  }

}
