import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; 
import { RouterModule } from '@angular/router';

import { MenuComponent } from '@app/shared/components/menu/menu.component';
//import { FormComponent } from '@app/shared/components/form/form.component';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { BreadcrumbsComponent } from '@app/shared/components/breadcrumb/breadcrumb.component';

import { StateDirective } from '@app/shared/directives/state/state.directive';
import { NAV_DROPDOWN_DIRECTIVES } from '@app/shared/directives/nav-dropdown.directive';
import { SIDEBAR_TOGGLE_DIRECTIVES } from '@app/shared/directives/sidebar.directive';
import { AsideToggleDirective } from '@app/shared/directives/aside.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule 
  ],
  declarations: [
    // Components
    MenuComponent,
//    FormComponent,
    ModalComponent,
    BreadcrumbsComponent,

    // Directives
    StateDirective,
    NAV_DROPDOWN_DIRECTIVES,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective
  ], 
  exports: [ 
    // Components
    MenuComponent,
//    FormComponent,
    BreadcrumbsComponent,

    // Directives
    StateDirective,
    NAV_DROPDOWN_DIRECTIVES,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective
  ]
})
export class SharedModule { }
