import { Component, OnInit } from '@angular/core';

import { AuthService } from '@app/core/services/auth/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  title: string;


  constructor(private _AuthService: AuthService) { }

  ngOnInit() {
    this.title = 'Escodeve';
  }

  logout() {
    this._AuthService.logout();
  }

}
