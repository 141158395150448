import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@app/core/services/auth/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  errorApi: boolean = false;
  errorAuth: boolean = false;
  form: FormGroup; 
  loginCtrl: FormControl; 
  pswCtrl: FormControl; 

  loginRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; 

  constructor(
    private _AuthService: AuthService,
    private router: Router, 
    private _FormBuilder: FormBuilder
  ) {
    this.loginCtrl = _FormBuilder.control('', [ 
      Validators.required, 
      Validators.pattern(this.loginRegex) 
    ]); 
    this.pswCtrl = _FormBuilder.control('', [ 
      Validators.required, 
      Validators.minLength(4) 
    ]); 
 
    this.form = _FormBuilder.group({ 
      login: this.loginCtrl, 
      psw: this.pswCtrl 
    }); 
  }

  ngOnInit() {
    this._AuthService.logout();
  }

  isError(champs: string) { 
    return this.form.get(champs).dirty && this.form.get(champs).hasError('pattern'); 
  } 
  
  login(e): void { 
    var login = e.target.elements[0].value;
    var pwd = e.target.elements[1].value;
    this._AuthService.login(login, pwd)
      .subscribe(
        res => {
          this.errorApi = false;
          if(res['success'] == true) {
            this.errorAuth = false;
            this.errorApi = false;
            this.router.navigate(['/dashboard']);
          } else {
            this.errorAuth = true;
            this.errorApi = false;
          }
        },
        err => { // API Down
          this.errorAuth = false;
          this.errorApi = true;
        }
      );
      
    // this.form.reset(); 
  }
  
}
