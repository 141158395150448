import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppGlobals } from '@app/core/app.globals';

@Injectable()
export class AuthService {
  api_url = this._global.baseAPIUrl + 'login/user';
  redirectUrl: string;


  constructor(private http: HttpClient, private router: Router, private _global: AppGlobals) { }

  login(login, pwd): Observable<any> {
    return this.http.post(this.api_url, {
      'username': login,
      'password': pwd
    }).pipe(
      tap(
        user => {
          if (user && user.token) {
            localStorage.setItem('currentUser', JSON.stringify(user));
          } else {
            localStorage.removeItem('currentUser');
          }
        },
        // error => this.logError(filename, error)
      )
    );
  }

  logout(): void {
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth']);
  }

}
