import { Directive, Input, ElementRef, Renderer2, SimpleChanges, OnChanges } from '@angular/core';

import { State } from '@app/shared/enums/state.enum';

@Directive({
  selector: '[appState]'
})
export class StateDirective implements OnChanges {
  @Input('appState') appState: State; 

  constructor(private _ElementRef: ElementRef, private _Renderer2: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void { 
    // console.log(this.appState); 
    const elementNode = this._ElementRef.nativeElement; 
    const cssClass = `state-${this.appState}`; 
    const state = State; 
    let texte: string; 
 
    switch (this.appState) { 
      case state.ALIVIER: 
        texte = 'A livrer'; 
        break; 
      case state.ENCOURS: 
        texte = 'En cours de livraison'; 
        break; 
      case state.LIVREE: 
        texte = 'Commande livrée'; 
        break; 
      default: 
        console.log(this.appState); 
        break; 
    } 
 
    this._Renderer2.addClass(elementNode, cssClass); 
    elementNode.textContent = texte; 
  } 

}
