import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// Routing
import { AppRoutingModule } from '@app/app-routing.module';

// Module
// import { ItemsModule } from '@app/items/items.module';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'angular2-moment';

import { JwtInterceptor } from '@app/_helpers/jwt.interceptor';

// Components
import { environment } from '@env/environment';
import { AppComponent } from '@app/app.component';
import { LayoutsComponent } from '@app/core/components/layouts/layouts.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    // ItemsModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    MomentModule
  ],
  declarations: [
    AppComponent,
    LayoutsComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router) {
    if (!environment.production) {
      //  console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
    }
  }
}
