import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule  } from '@angular/common/http';

import { CoreRoutingModule } from '@app/core/core-routing.module';
import { AppGlobals } from '@app/core/app.globals';

//import { CollectionService } from '@app/core/services/collection/collection.service';
import { AuthService } from '@app/core/services/auth/auth.service';
import { UserService } from '@app/core/services/user/user.service';
import { PdfService } from '@app/core/services/pdf/pdf.service';


import { AuthComponent } from '@app/core/components/auth/auth.component';
import { PageNotFoundComponent } from '@app/core/components/page-not-found/page-not-found.component';
import { AuthGuardService } from '@app/_guard/auth-guard.service';

@NgModule({
  imports: [
    CommonModule,
    CoreRoutingModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations: [
    AuthComponent,
    PageNotFoundComponent
  ],
  exports: [
    AuthComponent,
    PageNotFoundComponent
  ],
  providers: [
    AuthGuardService,
//    CollectionService,
    AuthService,
    UserService,
    PdfService,
    AppGlobals
  ]
})
export class CoreModule { }
