import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppGlobals } from '@app/core/app.globals';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators'

@Injectable()
export class UserService {
  api_url = '';
  redirectUrl: string;


  constructor(private http: HttpClient, private _global: AppGlobals) { }

  edit(phone, adress, cp): Observable<any> {
    this.api_url = this._global.baseAPIUrl+'users/edit';
    return this.http.post(this.api_url, {
      'phone': phone,
      'adress': adress,
      'cp': cp,
      'token': this.getToken()
    }).pipe(
      tap(
        response => {
          if (response && response.success) {
            var $userInfo = this.getCurrentUser();
            $userInfo['result'].tel_user = phone;
            $userInfo['result'].adresse_user = adress;
            $userInfo['result'].code_postale_user = cp;
            this.setCurrentUser($userInfo);
          } else {
            console.log('not ok !');
          }
        },
        // error => this.logError(filename, error)
      )
    );
  }

  add(): void {
    console.log('user add');
  }

  getToken(): string {
    return JSON.parse(localStorage.getItem('currentUser')).token;
  }

  getCurrentUser(): object {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  setCurrentUser(user): void {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  getCurrentUserInfo(): object {
    let user = this.getCurrentUser();
    return user['result'];
  }

  isLoggedIn(): boolean {
    let user = this.getCurrentUser();
    return user != null && user['success'] && user['token'];
  }

  parseJwt():Object {
    let parseJwt = '';

    if (this.isLoggedIn()) {
      let token = this.getToken();
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace('-', '+').replace('_', '/');
      parseJwt = JSON.parse(window.atob(base64));
      console.log(parseJwt);
    }
    return parseJwt;
  }

  getCurrentUserRole(): string {
    let user = this.parseJwt();
    return user['roles'];
  }

  arrayHasIndex(arrayElements, elem): boolean {
    return (arrayElements == null || arrayElements.indexOf(elem) != -1);
  }

  hasAccessRights(accessRights): boolean {
    let ishasAccess = false;
    let userRole = this.getCurrentUserRole();

    switch (accessRights) {
      case "manager":
        ishasAccess = this.arrayHasIndex(this._global.managerUsersRole, userRole);
        break;
      case "admin-site":
        ishasAccess = this.arrayHasIndex(this._global.adminSiteUsersRole, userRole);
        break;
      case "admin-central":
        ishasAccess = this.arrayHasIndex(this._global.adminCentralUsersRole, userRole);
        break;
      default:
        ishasAccess = false;
    }
    return ishasAccess;
  }

  isAdminSite(): boolean {
    //console.log("hasAccessRights="+)
    return this.hasAccessRights('manager');
  }


}
