import { Component, OnInit } from '@angular/core';

import { AuthService } from '@app/core/services/auth/auth.service';
import { UserService } from '@app/core/services/user/user.service';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss']
})
export class LayoutsComponent implements OnInit {

  public disabled = false;
  public status: {isopen: boolean} = {isopen: false};

  
  constructor(private _AuthService: AuthService, private _UserService: UserService,) { }

  ngOnInit() {
  }

  // public toggled(open: boolean): void {
  //   console.log('Dropdown is now: ', open);
  // }

  // public toggleDropdown($event: MouseEvent): void {
  //   $event.preventDefault();
  //   $event.stopPropagation();
  //   this.status.isopen = !this.status.isopen;
  // }

  logout() {
    this._AuthService.logout();
  }

  isAdminSite(): boolean {
    return this._UserService.isAdminSite();
  }

}
