import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@app/_guard/auth-guard.service';
import { AuthComponent } from '@app/core/components/auth/auth.component';
import { LayoutsComponent } from '@app/core/components/layouts/layouts.component';
import { PageNotFoundComponent } from '@app/core/components/page-not-found/page-not-found.component';

const coreRoutes: Routes = [
  { path: 'auth', component: AuthComponent },
  { path: '', canActivate: [AuthGuardService] , component: LayoutsComponent, data: { title: 'Home' },
  children: [
    { path: 'dashboard', loadChildren: '@app/dashboard/dashboard.module#DashboardModule' },
    { path: 'cra', loadChildren: '@app/cra/cra.module#CraModule' },
    { path: 'absence', loadChildren: '@app/absence/absence.module#AbsenceModule' },
    { path: 'document', loadChildren: '@app/document/document.module#DocumentModule' },
    { path: 'gestion-des-effectifs', loadChildren: '@app/effectifs/effectifs.module#EffectifsModule' },
    { path: 'password-edit', loadChildren: '@app/password/password.module#PasswordModule' },
    { path: '**', component: PageNotFoundComponent },
  ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(coreRoutes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
